<template>
  <div class="row custom-form-wrapper">
    <div class="col-md-8 col-sm-10 col-md-offset-2 col-sm-offset-1">
      <div class="custom-form app-res">
        <div class="panel-body">
          <div class="panel panel-info app-search-header-panel">
            <div class="row app-search-wrapper">
              <div class="col-md-10 col-md-offset-1">
                <div class="app-search-header">
                  <div class="row app-search-header-row">
                    <div class="col-md-4 header-left">
                      <span>Select City</span>
                    </div>
                    <div class="col-md-6 header-middle">
                      <select
                        id="filterCity"
                        name="filterCity"
                        @change="onChangeCity"
                        v-model.trim="filterCity"
                        class="form-control"
                      >
                        <option></option>
                        <option
                          v-for="item in cityList"
                          v-bind:value="item.value"
                          :key="item.value"
                          >{{ item.label }}</option
                        >
                      </select>
                    </div>
                    <div class="col-md-2 header-right">
                      <span
                        v-if="aniLabelsLoading"
                        class="fa fa-spinner fa-spin fa-2x fa-fw"
                      ></span>
                    </div>
                  </div>

                  <div class="row app-search-header-row">
                    <div class="col-md-4 header-left">
                      <span>Select Locality</span>
                    </div>
                    <div class="col-md-6 header-middle">
                      <select
                        id="filterSelect"
                        name="filterSelect"
                        @change="getFormDataBySelect"
                        v-model.trim="filterSelect"
                        class="form-control"
                      >
                        <option></option>
                        <option
                          v-for="item in localityList"
                          v-bind:value="item.value"
                          :key="item.value"
                          >{{ item.label }}</option
                        >
                      </select>
                    </div>
                    <div class="col-md-2 header-right">
                      <!-- <span
                        v-if="aniLabelsLoading"
                        class="fa fa-spinner fa-spin fa-3x fa-fw"
                      ></span> -->
                    </div>
                  </div>

                  <div class="row app-search-header-row">
                    <div
                      class="col-md-6 col-md-offset-3 app-search-header-btn-add"
                    >
                      <button
                        @click="newFormData"
                        type="submit"
                        class="btn btn-success"
                      >
                        Add New Locality
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="panel-body">
          <div class="panel panel-primary">
            <div class="panel-heading">
              <h3 class="panel-title">Locality details</h3>
            </div>
            <div class="panel-body">
              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('loc_Name') }"
              >
                <label for="loc_Name">Locality name</label>
                <input
                  id="loc_Name"
                  name="loc_Name"
                  v-model.trim="fdata.loc_Name"
                  v-validate.initial="{ required: true, max: 100 }"
                  maxlength="100"
                  class="form-control"
                />
                <span class="help-block">{{ errors.first("loc_Name") }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('loc_Highlights') }"
              >
                <label for="loc_Highlights">Locality highlights</label>
                <textarea
                  id="loc_Highlights"
                  name="loc_Highlights"
                  v-model.trim="fdata.loc_Highlights"
                  v-validate.initial="{ max: 2000 }"
                  maxlength="2000"
                  rows="4"
                  class="form-control"
                ></textarea>
                <span class="help-block">{{
                  errors.first("loc_Highlights")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('loc_SortValue') }"
              >
                <label for="loc_SortValue">Sort value</label>
                <input
                  id="loc_SortValue"
                  name="loc_SortValue"
                  v-model.trim="fdata.loc_SortValue"
                  v-validate="{ numeric: true }"
                  maxlength="10"
                  class="form-control"
                />
                <span class="help-block">{{
                  errors.first("loc_SortValue")
                }}</span>
              </div>

              <div
                class="form-group"
                v-bind:class="{ 'has-error': errors.has('loc_Status') }"
              >
                <label for="loc_Status">Status</label>
                <select
                  id="loc_Status"
                  name="loc_Status"
                  v-model="fdata.loc_Status"
                  v-validate.initial="{ required: true }"
                  class="form-control"
                >
                  <option></option>
                  <option value="1">Active</option>
                  <option value="2">Inactive</option>
                  <option value="90">Delete</option>
                </select>
                <span class="help-block">{{ errors.first("loc_Status") }}</span>
              </div>
            </div>

            <div class="btn-submit-wrapper">
              <div v-if="showAnimation" class="app-animation">
                <span class="fa fa-spinner fa-spin fa-3x fa-fw"></span><br />
                <span class="text">Form submission in progress</span>
              </div>
              <button
                v-on:click="saveFormData"
                type="submit"
                class="btn btn-success"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
        <div>
          <!-- 
             <div class="vue-recaptcha-wrapper">
                <vue-recaptcha ref="cap_recaptcha" id="cap_recaptcha" v-on:verify="verifyRecaptcha" sitekey="6LekXD0UAAAAAKHK2xh4FbzELM5T9wyHAzyiGIWS"></vue-recaptcha>
             </div>
              -->
          <modal
            name="alert-dialog-locality"
            :width="400"
            :height="'auto'"
            :clickToClose="false"
          >
            <div class="panel-primary">
              <div class="panel-heading modal-alert-heading">
                <span class="modal-alert-title">Information</span>
                <button
                  @click="closeAlertDialog"
                  type="button"
                  class="close pull-right"
                >
                  <span
                    class="fa fa-times-circle modal-alert-close-button-icon"
                  ></span>
                </button>
              </div>
              <div class="panel-body modal-alert-body">
                <div class="modal-alert-text-wrapper">
                  <span
                    class="fa fa-exclamation-circle modal-alert-icon"
                  ></span>
                  <span class="modal-alert-text">
                    {{ alertMessage }}
                  </span>
                </div>
                <div class="modal-alert-footer">
                  <button
                    @click="closeAlertDialog"
                    type="button"
                    class="btn btn-default"
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import axios from "axios";
export default {
  props: {
    baseUrl: { type: String }
  },
  data() {
    return {
      fdata: {
        pkey: 0,
        loc_CtyFkey: null,
        loc_Name: "",
        loc_Highlights: "",
        loc_SortValue: null,
        loc_Status: null
      },
      showAnimation: false,
      alertMessage: "",
      cityList: [],
      localityList: [],
      filterCity: "",
      filterSelect: "",
      aniLabelsLoading: false
    };
  },
  methods: {
    ...mapActions(["getToken"]),
    saveFormData: async function() {
      const valStatus = await this.$validator.validateAll();
      if (!valStatus) {
        this.alertMessage = "Form contains validation warnings";
        this.$modal.show("alert-dialog-locality");
        return;
      }
      if (!this.filterCity) {
        this.alertMessage = "Please select City/Town";
        this.$modal.show("alert-dialog-locality");
        return;
      }
      this.showAnimation = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}SaveFormData`;
      this.fdata.loc_CtyFkey = this.filterCity;
      try {
        ({ data: rData } = await axios.post(actionUrl, this.fdata, config));
      } catch (error) {
        this.showAnimation = false;
        throw error;
      }

      if (rData.status != 0) {
        this.showAnimation = false;
        this.alertMessage = "Form contains validation warnings";
        this.$modal.show("alert-dialog-locality");
        return;
      }
      if (!this.fdata.pkey) {
        this.resetFormData();
      }
      await this.getCityList();
      await this.getLocalityList();

      this.alertMessage = "Form submitted successfully";
      this.$modal.show("alert-dialog-locality");
      this.showAnimation = false;
    },

    getFormDataBySelect: async function() {
      if (!this.filterSelect) {
        this.resetFormData();
        return;
      }
      this.aniLabelsLoading = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { pkey: this.filterSelect }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}GetFormDataBySelect`;
      try {
        ({ data: rData } = await axios.get(actionUrl, config));
      } catch (error) {
        this.aniLabelsLoading = false;
        throw error;
      }
      if (rData) {
        Object.assign(this.fdata, rData);
      }
      this.aniLabelsLoading = false;
    },
    getLocalityList: async function() {
      this.aniLabelsLoading = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        },
        params: { fkey: this.filterCity }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}GetLocalityList`;
      try {
        ({ data: rData } = await axios.get(actionUrl, config));
      } catch (error) {
        this.aniLabelsLoading = false;
        throw error;
      }
      if (rData.length != 0) {
        this.localityList = Object.assign([], rData);
      } else {
        this.filterSelect = "";
        this.localityList = [];
        this.resetFormData();
      }
      this.aniLabelsLoading = false;
    },
    getCityList: async function() {
      this.aniLabelsLoading = true;
      const token = await this.getToken({ vm: this });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`
        }
      };
      let rData = null;
      let actionUrl = `${this.baseUrl}GetCityList`;
      try {
        ({ data: rData } = await axios.get(actionUrl, config));
      } catch (error) {
        this.aniLabelsLoading = false;
        throw error;
      }
      if (rData) {
        this.cityList = Object.assign([], rData);
      }
      this.aniLabelsLoading = false;
    },
    onChangeCity: async function() {
      if (!this.filterCity) {
        this.localityList = [];
        this.resetFormData();
      } else {
        await this.getLocalityList();
      }
    },
    closeAlertDialog: function() {
      this.$modal.hide("alert-dialog-locality");
    },
    newFormData: function() {
      this.resetFormData();
      this.filterSelect = "";
    },
    resetFormData: function() {
      Object.assign(this.fdata, {
        pkey: 0,
        loc_CtyFkey: null,
        loc_Name: "",
        loc_Highlights: "",
        loc_SortValue: null,
        loc_Status: null
      });
    }
  },
  async mounted() {
    await this.getCityList();
  }
};
</script>
